.App {
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



.menu-close {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 11px;
}
.menu-section {
  position: absolute;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
  overflow-y: auto;
  display: none;
  width: 100%;
  z-index: 1;
}
.menu-section.active {
  display: block;
}
img.main-bg {
  width: 100%;
  position: fixed;
  left: 0;
  top: -95px;
  z-index: 10;
  opacity: 0.3;
}
.menu-section img.main-bg {
  opacity: 1;
}
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #1f1d15;
  z-index: 1;
}
.menu-section .top-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 13px;
  z-index: 10;
  position: absolute;
  margin-bottom: 0;
  left: 0;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}
.menu-section .top-block img,
.menu-section .top-block svg {
  display: block;
}
.menu-section .top-block a.avatar-link {
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.5);
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-section .congratulations-block {
  background: #c7b345;
  box-shadow: 0 2px 18px 4px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  max-width: 320px;
  width: 100%;
  padding: 35px 20px 42px 20px;
  text-align: center;
  text-decoration: none;
  display: block;
  z-index: 10;
  position: relative;
  margin: 0 auto;
  margin-bottom: 46px;
  margin-top: 116px;
  box-sizing: border-box;
}
.menu-section .congratulations-block .title {
  margin-bottom: 31px;
  font-family: "Myanmar MN";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}
.menu-section .congratulations-block .description {
  margin-bottom: 31px;
  font-family: "Helvetica LT Std";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #ffffff;
}
.menu-section .congratulations-block .make-video {
  background: #b6a548;
  display: flex;
  align-items: center;
  max-width: 280px;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  padding: 12px 15px;
  font-family: "Myanmar MN";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: #ffffff;
  border-radius: 10px;
}
.menu-section .congratulations-block .make-video svg {
  display: block;
  margin-right: 17px;
}
.menu-section .social-link {
  box-sizing: border-box;
  margin-bottom: 18px;
  position: relative;
  z-index: 10;
  text-align: center;
}
.menu-section .social-link a {
  display: flex;
  align-items: center;
  max-width: 320px;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  box-shadow: 0 2px 18px 4px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  font-family: "Myanmar MN";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.15px;
  color: #ffffff;
  text-decoration: none;
  margin: auto;
  padding: 0 23px;
}
.menu-section .social-link a span {
  padding-left: 20px;
}
.menu-section .social-link a img {
  display: block;
}
.menu-section .social-link a.snapchat-mask {
  margin-bottom: 16px;
  background: #f1897e;
}
.menu-section .social-link a.instagram-mask {
  background: #7eb8c0;
}

.main-menu-right .title {
  margin-bottom: 26px;
  font-family: "Myanmar MN";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.15px;
  color: #000000;
  text-align: center;
  box-sizing: border-box;
}
.main-menu-right .title svg {
  width: 10%;
  height: auto;
  fill: white;
}

.main-menu-right .menu-block-close svg {
  display: block;
  width: 200%;
  height: auto;
  fill: white;
}
.main-menu-right .menu-block-close {
  position: absolute;
  top: 28px;
  right: 10%;
  z-index: 1;
}

.main-menu-right .menu-block-arrow svg {
  display: block;
  width: 200%;
  height: auto;
  fill: white;
}
.main-menu-right .menu-block-arrow {
  position: absolute;
  top: 28px;
  left: 5%;
  z-index: 1;
}
.main-menu-right .menu-block-list svg {
  display: block;
  width: 200%;
  height: auto;
  fill: white;
}
.main-menu-right .menu-block-list {
  position: absolute;
  top: 28px;
  left: 50%;
  z-index: 1;
}

.main-menu-right .menu-block-item a {
  background: #0a8eff;
  width: 100%;
  height: 16%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: "Myanmar MN";
  font-style: normal;
  font-weight: bold;
  font-size: 120%;
  line-height: 22px;
  color: #ffffff;
  text-decoration: none;
  margin: 0 auto;
}

.sub-menu .title {
  margin-bottom: 26px;
  font-family: "Myanmar MN";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  letter-spacing: 0.15px;
  color: #000000;
  text-align: center;
  box-sizing: border-box;
}
.sub-menu .title svg {
  width: 10%;
  height: auto;
  fill: white;
}

.sub-menu .menu-block-close svg {
  display: block;
  width: 200%;
  height: auto;
  fill: white;
}
.sub-menu .menu-block-close {
  position: absolute;
  top: 28px;
  right: 10%;
  z-index: 1;
}

.sub-menu .menu-block-arrow svg {
  display: block;
  width: 200%;
  height: auto;
  fill: white;
}
.sub-menu .menu-block-arrow {
  position: absolute;
  top: 28px;
  left: 5%;
  z-index: 1;
}
.sub-menu .menu-block-list svg {
  display: block;
  width: 200%;
  height: auto;
  fill: white;
}
.sub-menu .menu-block-list {
  position: absolute;
  top: 28px;
  left: 50%;
  z-index: 1;
}

.sub-menu .sub-menu-block-item a {
  background: #ff0ab5;
  width: 100%;
  height: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: "Myanmar MN";
  font-style:-normal;
  font-weight: bold;
  font-size: 80%;
  line-height: 22px;
  color: #ffffff;
  text-decoration: none;
}

.menu-block-item {
  display: inherit;
  width: 100%;
  height: 70%;
}

.sub-menu-block-item {
  display: inherit;
  width: 100%;
  height: 100%;
}

@media (orientation: landscape) {
  .main-menu-right .menu-block-content {
    background: rgba(255, 255, 255, 0.6);
       background-image: url(./assets/images/e-xr-panel_menu-v2_bg.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    backdrop-filter: blur(10px);
    border-radius: 3vh 3vh 3vh 3vh;
    box-sizing: border-box;
    text-align: center;
    padding: 5px 5px 5px 5px;
    position: fixed;
    top: 50vh;
    right: 0;
    width: 23vw;
    height: 32vw;
    z-index: 1;
    transition: all 0.4s;
    transform: translate(98%, -50%) scale(1.0, 1.0);

  }

  .main-menu-right.active .menu-block-content {
    transform: translate(-160%, -50%);
  }

  .sub-menu .menu-block-content {
    background: rgba(255, 255, 255, 0.6);
       background-image: url(./assets/images/e-xr-panel_menu-v2_bg.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    backdrop-filter: blur(10px);
    border-radius: 3vh 3vh 3vh 3vh;
    box-sizing: border-box;
    text-align: center;
    padding: 5px 5px 5px 5px;
    position: fixed;
    top: 50vh;
    left: 0;
    width: 23vw;
    height: 32vw;
    z-index: 1;
    transition: all 0.4s;
    transform: translate(-99%, -50%) scale(1.0, 1.0);

  }

  .sub-menu.active .menu-block-content {
    transform: translate(170%, -50%);
  }
}

@media (orientation: portrait) {
  .main-menu-right .menu-block-content {
    background: rgba(255, 255, 255, 0.6);
    background-image: url(./assets/images/e-xr-panel_menu-v2_bg.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    backdrop-filter: blur(10px);
    border-radius: 4.5vw 4.5vw 4.5vw 4.5vw;
    box-sizing: border-box;
    text-align: center;
    padding: 29px 5px 28px 5px;
    position: fixed;
    top: 50vh;
    right: 0;
    width: 72vw;
    height: 100vw;
    z-index: 1;
    transition: all 0.4s;
    transform:  translate(99%, -50%) scale(1.0, 1.0);
  }

  .main-menu-right.active .menu-block-content {
    transform: translate(-20%, -50%) scale(1.0, 1.0);
  }

  .sub-menu .menu-block-content {
    background: white;
       background-image: url(./assets/images/e-xr-panel_menu-v2_bg.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    backdrop-filter: blur(10px);
    border-radius: 0.1vw 0.1vw 0.1vw 0.1vw;
    box-sizing: border-box;
    text-align: center;
    padding: 29px 5px 28px 5px;
    position: fixed;
    top: 50vh;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    transition: all 0.4s;
    transform:  translate(-99%, -50%) scale(1.0, 1.0);
  }

  .sub-menu.active .menu-block-content {
    transform: translate(0%, -50%) scale(1.0, 1.0);
  }
}

.main-menu-right {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.main-menu-right .menu-block-item a:not(:last-of-type) {
  margin-bottom: 4%;
}
.main-menu-right .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000000;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.6);
  transition: all 0.4s;
  z-index: 1;
}
.main-menu-right.active .overlay {
  opacity: 0.4;
  transform: scale(1);
  visibility: visible;
}

.sub-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.sub-menu .sub-menu-block-item a:not(:last-of-type) {
  margin-bottom: 4%;
}
.sub-menu .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000000;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.6);
  transition: all 0.4s;
  z-index: 1;
}
.sub-menu.active .overlay {
  opacity: 0.4;
  transform: scale(1);
  visibility: visible;
}

@viewport {
  user-zoom: fixed;
}

#camera {
  /*height: 100%;
    width: 100%;*/
  position: fixed;
  position: -ms-device-fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1px;
  -webkit-border-radius: 1px;
  /*-webkit-object-fit: cover !important;
    object-fit: cover !important;*/
  object-position: center;
}

.a-canvas {
  z-index: 0 !important;
  pointer-events: none;
}

.top_bar_without_logo {
  width: 80vw;
  height: 40vw;
  left: 10vw;
  top: 40vh;
  position: absolute;
  z-index: 22;
  background-image: url(./assets/images/sound_bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.access_on {
  position: inherit;
  bottom: 0;
  width: 40%;
  height: 50%;
  left: 55%;
  z-index: 25;
  background-image: url(./assets/images/sound_continue.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.access_off {
  position: inherit;
  bottom: 0;
  width: 40%;
  height: 50%;
  left: 5%;
  z-index: 25;
  background-image: url(./assets/images/sound_cancel.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.appearButton {
  position: absolute;
  bottom: 3vw;
  width: 20vw;
  height: 20vw;
  right: 3vw;
  z-index: 25;
  background-image: url(./assets/images/appearButton.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.shareButton {
  position: absolute;
  bottom: 3vw;
  width: 20vw;
  height: 20vw;
  left: 3vw;
  z-index: 25;
  background-image: url(./assets/images/shareButton.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#iyp_loading {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 101;
  pointer-events: none;
  background-color: #000000;
  background-image: url(./assets/images/loading.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#iyp_loading_2 {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 101;
  pointer-events: none;
  background-color: #000000;
  background-image: url(./assets/images/loading.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#loading_logo {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 10em;
  width: 10em;
  transform: translate(-50%, -50%);
  background-image: url(./assets/images/3rock_loading.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: -5em;
  margin-top: -5em;
  animation: pulse 1s ease-in-out 0s infinite normal none;
  -webkit-animation: pulse 2s ease-in-out 0s infinite normal none;
  backface-visibility: hidden;
}

#loading_logo_2 {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 10em;
  width: 10em;
  transform: translate(-50%, -50%);
  background-image: url(./assets/images/3rock_loading.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: -5em;
  margin-top: -5em;
  animation: pulse 1s ease-in-out 0s infinite normal none;
  -webkit-animation: pulse 2s ease-in-out 0s infinite normal none;
  backface-visibility: hidden;
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(.8);
  }
  100% {
      transform: scale(1);
  }
}
@-webkit-keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(.8);
  }
  100% {
      transform: scale(1);
  }
}


#geo_position {
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  position: absolute;
  z-index: 101;
  pointer-events: none;
  color: aqua;
  font-size: small;
}

.compass {
  position: absolute;
  width: 50vw;
  height: 50vw;
  left: 25vw;
  border-radius: 50%;
  margin: auto;
  z-index: 1000;
}

.compass > .arrow {
  position: absolute;
  width: 0;
  height: 0;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 30px 20px 0 20px;
  border-color: red transparent transparent transparent;
  z-index: 1;
}

.compass > .compass-circle,
.compass > .my-point {
  position: absolute;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.1s ease-out;
  background: url(https://purepng.com/public/uploads/large/purepng.com-compasscompassinstrumentnavigationcardinal-directionspointsdiagram-1701527842316onq7x.png)
    center no-repeat;
  background-size: contain;
}

.compass > .my-point {
  opacity: 0;
  width: 20%;
  height: 20%;
  background-color: rgb(8, 223, 69);
  border-radius: 50%;
  transition: opacity 0.5s ease-out;
}

.start-btn {
  position: absolute;
  right: 0;
  margin-bottom: auto;
  z-index: 1000;
  background-color: aliceblue;
}


.arrow_left {
  position: absolute;
  top: 20%;
  left:0;
  height: 10%;
  width: 50%;
  transform: translate(-50%, -50%);
  background-image: url(./assets/images/red-right-arrow.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.arrow_right {
  position: absolute;
  top: 20%;
  right:0;
  height: 10%;
  width: 50%;
  transform: translate(-50%, -50%);
  background-image: url(./assets/images/red-left-arrow.png);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.streaming-section
{ position: absolute;
  bottom: 5vw;
  left:0;
  height: 20vw;
  width: 50%;
}

#embdedPdf{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

#pdf_viwer{
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: block;
  color: white;
}

#the-canvas {
  border: 1px solid black;
  direction: ltr;
}

.poweredby-img {
  display: none !important;
}


.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  max-width: 50%;
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}

.form-control {
  background-color:  #b2d3e0!important;
}

.form-control:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.Heading-discription{
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 5px; */
  margin-bottom: 35px;
  /* background-color: #28a745; */
}

.Heading-discription h2{
  font-size: 30px;
  color: aliceblue;
}

.Generated-codes{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 30px;
  /* background-color: aqua; */
}

.Generated-qrcode{
  display: grid;
  justify-content: center;
  align-items: center;
  /* background-color: blanchedalmond; */
}

.Generated-qrcode-box{
  width: 300px;
  height: 300px;
  /* border-radius: 5px; */
  /* margin-right: 145px; */
  border: 25px solid rgb(41, 119, 140);
  background-color: none;
}

.Generated-qrcode-copy{
  width: 50%;
  padding: 10px;
  margin-top: 30px;
  margin-left: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 5px; */
  background-color: rgb(153, 196, 208);
}

.Generated-link{
  display: grid;
  justify-content: center;
  align-items: center;
  /* background-color: blanchedalmond; */
}

.Generated-link-box{
  width: 300px;
  padding: 25px;
  margin-top: 210px;
    /*
  margin-left: 145px;
 border-radius: 5px; */
  background-color: rgb(41, 119, 140);
}

.Generated-link-copy{
  width: 50%;
  padding: 10px;
  margin-top: 75px;
  margin-left: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 5px; */
  background-color: rgb(153, 196, 208);
}

.Generated-copy-text{
  font-size: small;
}


.buttons {
	border : solid 0px #e6b215;
	border-radius : 8px;
	-moz-outline-radius : 8px;
	font-size : 16px;
	color : #ffffff;
	padding : 5px 18px;
	background-color : #FF9900;
	cursor:pointer;
}

.buttons:hover {
	background-color:#ffc477;
}

.buttons:active {
	position:relative;
	top:1px;
}

#newPost {
	margin: 0 auto;
	width: 90%;
}

#charCounter { float:right }

textarea {
  width:		100%;
	height: 	10em;
}

#content {
  width: 100% ;
  margin-left: auto ;
  margin-right: auto;
	margin-bottom: 10px;
	font-family:verdana, sans-serif;
	word-spacing:4pt;
	font-size:14px;
}
/*
#posts {
	font-weight:normal;
	color:#000000;
	word-spacing:4pt;
	font-size:10px;
	text-align:left;
	font-family:verdana, sans-serif;
	width: 90%;
	margin: 0 auto;
}

#posts th {
	background-color: #FF9900;
	color: white;
	padding: 8px;
	border-bottom: 1px solid #ddd;
}

#posts td {
	padding: 8px;
	border-color: #666666;
	background-color: #ffffff;
	border-bottom: 1px solid #ddd;
}
*/

#camerafeed{
  position: absolute;
}

audio{
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  object-fit: contain;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

:root{
  --cs: 30px;
  --br: 20px;
}

.roott {
  display: flex;
  height: 100vh;
  align-items: center;
  
}

.content {
  position: relative;
  margin: auto;
  border-radius: var(--br);
  background-color: #ffffff;
  color: #363c5f;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  clip-path: polygon(0 0, calc(100% - var(--cs)) 0, 100% var(--cs), 100% 100%, 0 100%);
}

.content::after {
  content: '';
  position: absolute;
  display: block;
  width: var(--cs);
  height: var(--cs);
  background-color: #8a8aaa;
  top: 0;
  right: 0;
  border-bottom-left-radius: var(--br);
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);



#button {
  font-size: 28px;
  font-weight: bold;
  color: white;
  background: #31682F;
  border: none;
  position: relative;
  padding: 0.5em 1em;
  margin: 1.5em;
  border-radius: 15px 15px 15px 15px;
  overflow: hidden;
  transition: border-radius 0s;
  /*clip-path: polygon(0 23px, 0 100%, 100% 100%, 100% 0, 23px 0);*/
}
#button:hover {
  border-radius: 15px 15px 15px 15px;
  transition-delay: .2s;
  clip-path: polygon(0 23px, 0 100%, 100% 100%, 100% 0, 23px 0);
}
#button:hover:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-style: solid;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.4);
  width: 0;
  border-width: 12px;
  border-color: #31682F rgba(255,255,255,0.3) rgba(255,255,255,0.3) #31682F;
  border-radius: 0 0 15px 0;
  transition: border-width .2s;

}



.right-button .right-button-content {
  background: rgba(255, 255, 255, 0.6);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  backdrop-filter: blur(10px);
  border-radius: 5px 0px 0px 5px;
  border-color: white;
  border-width: 2px;
  box-sizing: border-box;
  padding: 1px 1px 1px 1px;
  position: fixed;
  top: 30vh;
  right: 0;
  width: 30px;
  height: 80px;
  z-index: 1;
  transition: all 0.4s;
  transform: translate(100%, -50%) scale(1.0, 1.0);


}


.right-button.active .right-button-content {
  transform: translate(0%, -50%);
  text-align: center;
}

.right-button .right-button-content a {
  padding: 0.3em;
  text-align: center;
  display: inline-block;
  text-decoration: none !important;
  margin: 0 auto;
  writing-mode: vertical-rl;
  text-orientation: upright;
  color: white;
  text-align: center;
  font-size: small;
}
